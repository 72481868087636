.table-context {
  padding: 0;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  .search-group {
    // width: unset;
    max-width: 240px;
    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid var(--gray-neutral-600);
      border-right: none;
      color: var(--gray-neutral-300);
      padding: 8px 0 8px 8px;
      background-color: var(--gray-neutral-900);
    }

    .form-control {
      @extend .text-sm;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid var(--gray-neutral-600);
      border-left: none;
      padding: 8px;
      color: var(--gray-neutral-300);
      background-color: var(--gray-neutral-900);
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.table-content {
  margin-bottom: 32px;
  .custom-table {
    border-radius: 8px;
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;
    .table-head {
      th {
        @extend .text-semibold-xs;
        border: 0;
        border-bottom: 1px solid var(--gray-modern-200);
        background-color: var(--gray-modern-200);
        color: var(--gray-neutral-500);
        padding: 12px 7px;
        &:first-child {
          border-left: 1px solid var(--gray-modern-200);
          padding: 12px 7px 12px 16px;
        }
        &:last-child {
          border-right: 1px solid var(--gray-modern-200);
          padding: 12px 16px 12px 7px;
        }
      }
    }

    .table-body {
      border: 0;
      td {
        @extend .text-xs;
        border-color: var(--gray-neutral-200);
        background-color: var(--base-white);
        color: var(--gray-neutral-700) !important;
        padding: 14px 7px;
        &:first-child {
          border-left: 1px solid var(--gray-neutral-200);
          padding: 14px 7px 14px 16px;
        }
        &:last-child {
          border-right: 1px solid var(--gray-neutral-200);
          padding: 14px 16px 14px 7px;
        }

        .task-title-container {
          .task-title {
            @extend .text-xs;
            color: var(--gray-neutral-400) !important;
          }
          .task-infor {
            @extend .text-xs;
            color: var(--gray-modern-900) !important;
          }
        }

        .empty-icon {
          width: 20px;
          height: 20px;
        }
      }
      tr {
        &:last-child {
          td {
            &:first-child {
              border-radius: 0 0 0 8px !important;
            }
            &:last-child {
              border-radius: 0 0 8px 0;
            }
          }
        }
      }
    }
  }

  .table-action-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .action-button {
      display: flex;
      padding: 10px 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: var(--gray-modern-500);
      background: var(--base-white);
      border-top: 1px solid var(--gray-modern-300);
      border-bottom: 1px solid var(--gray-modern-300);
      border-right: 1px solid var(--gray-modern-300);
      cursor: pointer;
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border: 1px solid var(--gray-modern-300);
        border-left: none;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border: 1px solid var(--gray-modern-300);
      }

      &:hover {
        background-color: var(--gray-modern-100);
      }

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.tree-icon {
  cursor: pointer;
  stroke: var(--gray-modern-900);
}

.custom-table-footer {
  .table-pagination {
    .page-item {
      .page-link-default {
        @extend .text-semibold-sm;
        z-index: 0;
        padding: 6px 12px;
        height: 100%;
      }
      .page-link {
        @extend .page-link-default;
        background-color: var(--base-white);
        border-color: var(--gray-modern-200);
        color: var(--gray-modern-700);
        &:hover {
          // border-color: var(  --primary-900);
          background-color: var(--primary-100);
        }
      }
      &.active {
        .page-link {
          @extend .page-link-default;
          background-color: var(--primary-500);
          border-color: var(--primary-500);
          color: var(--base-white);
        }
      }

      &.disabled {
        .page-link {
          @extend .page-link-default;
          background-color: var(--gray-neutral-100);
          border-color: var(--gray-neutral-300);
          color: var(--gray-neutral-400);
        }
      }
    }
  }

  .pagi-select {
    min-width: 80px;
  }
  .pagi-label {
    @extend .text-xs;
    color: var(--gray-neutral-200) !important;
  }
}

.page-title-container {
  background-color: var(--base-white);
  display: flex;
  padding: 20px 32px;
  align-items: center;
  width: 100%;

  .page-title {
    @extend .text-display-bold-xs;
    color: var(--gray-modern-900);
  }
}

.page-tab-container {
  background-color: var(--base-white);
  display: flex;
  padding: 0 32px;
  align-items: center;
  width: 100%;

  .custom-nav {
    border-bottom: none;
    gap: 20px;
    .nav-item {
      @extend .text-medium-sm;

      .nav-link {
        padding: 18px 0;
        color: var(--gray-modern-700);
        &.active {
          background: transparent;
          border-color: transparent;
          box-shadow: 0px -3px 0px 0px var(--primary-500) inset;
          &:hover {
            background: transparent;
            border-color: transparent;
            box-shadow: 0px -3px 0px 0px var(--primary-500) inset;
          }
        }
        &:hover {
          color: var(--primary-300);
          background: transparent;
          border-color: transparent;
        }
      }
    }
  }
}

.tab-outlet-content {
  padding: 32px;
}

.form-switch {
  .form-check-input {
    height: 20px;
    width: 36px;
    &:checked {
      background-color: var(--green-500);
      border-color: var(--green-500);
    }
  }
}

.page-outlet-content {
  padding: 0 32px;

  .table-context {
    padding: 20px 0;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &.bg-gray-modern-50 {
    background-color: var(--gray-modern-50);
  }
}

.avatar-container-display {
  display: flex;
  align-items: center;
  gap: 26px;
  .header-title {
    @extend .text-semibold-sm;
    color: var(--gray-modern-900);
  }

  .header-note {
    @extend .text-xs;
    color: var(--gray-modern-500);
  }
}

.avatar-header-display {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 10px 0;
  .header-title {
    @extend .text-medium-xl;
    color: var(--gray-neutral-900);
    align-self: center;
  }
}

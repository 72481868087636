// If you want to override variables do it here
// @import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui-pro/scss/coreui';

// Import gantt chart styles
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-react-gantt/styles/material.css';

@import 'common/index';

@import 'components/index';

@import 'view/index';

@import 'global';


.grid-container {
    text-align: center;
    margin: auto;
    width: 100%;

    .container {
        padding-left: 0;
        padding-right: 0;
        width: 100% !important;
    }

    .border {
        border: 1px solid var(--base-white) !important;
        border-radius: 6px;
    }

    .background {
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        cursor: pointer;
    }

    .height-one {
        padding-top: 50%;
    }

    .height-two {
        padding-top: 50%;
    }

    .height-three {
        padding-top: 33.3333%;
    }

    .cover {
        background-color: #222;
        opacity: 0.8;
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        border-radius: 6px;
    }

    .cover-text {
        right: 0;
        left: 0;
        bottom: 0;
        color: white;
        font-size: 7%;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0%, -50%);
        -ms-transform: translate(0%, -50%);
        transform: translate(0%, -50%);
        text-align: center;
    }

    .cover-text>p {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .slide {
        height: 0;
        bottom: 100%;
        transition: .5s ease;
        overflow: hidden;
        font-size: 3%;
    }

    .border:hover .slide {
        bottom: 0;
        height: auto;
    }

    .border:hover .animate-text {
        top: 62%
    }
}


.img-fullsize-modal {
    .modal-dialog {
        height: 100%;
        max-width: none !important;
        margin: 0 !important;

        .modal-content {
            height: 100%;
            border: 0;
            background: none;
        }
    }

    .img-fullsize {
        width: 100vw;
        height: 100vh;

        img {
            max-width: -webkit-fill-available;
            max-height: 100vh;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        max-height: 100px;
        margin: auto;
    }
}
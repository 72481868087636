.aside-custom {
  .side-noti-container {
    overflow-y: scroll;
  }

  .aside-navbar {
    .nav-link.active {
      color: var(--primary-500);
      border-color: var(--primary-500);
    }

    .close-nav:hover {
      color: #e55353;
      border-color: #e55353;
    }
  }

  .readAll-btn {
    &:hover {
      background-color: #e6f4d7 !important;
    }
  }
}

.custom-sidebar {
  box-shadow: none;
  border-right: 1px solid var(--gray-modern-900) !important;

  .sidebar-brand {
    flex: 0 0 48px;
    justify-content: start;
    align-items: center;
    padding: 22px 112px 26px 24px;
    background-color: var(--gray-modern-900);
  }

  .sidebar-toggler {
    background-color: var(--gray-modern-900);
  }

  .sidebar-nav {
    background-color: var(--gray-modern-900);

    .nav-title {
      @extend .text-bold-xs;
      white-space: break-spaces;
      // gap: 16px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      padding: 8px 24px;
      color: var(--gray-modern-500);
      text-transform: uppercase;

      svg {
        stroke: var(--gray-modern-500);
      }
    }

    .nav-group,
    .nav-item {
      .nav-link {
        @extend .text-medium-sm;
        white-space: break-spaces;
        padding: 12px 20px;
        gap: 12px;
        color: var(--gray-modern-300);

        .custom-nav-icon {
          color: var(--gray-modern-300);
        }

        &:hover,
        &.active {
          color: var(--base-white);
          background: var(--sidebar-active-bg);

          .custom-nav-icon {
            color: var(--base-white);
          }
        }

        &.favorite-nav {
          padding: 12px 20px 12px 56px;
        }
      }

      &.show {
        // background-color: unset;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--gray-neutral-800);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: var(--gray-modern-900);
    border-radius: 5px;
  }
}

.custom-header {
  padding: 0 0;
  min-height: 72px;
  background-color: var(--base-white);
  border-bottom: 1px solid var(--gray-modern-300);

  .header-container {
    padding: 0 32px;
    min-height: 72px !important;

    .header-title {
      @extend .text-display-medium-xs;
      color: var(--gray-neutral-900);
      align-self: center;
    }

    .header-nav {
      gap: 4px;
    }

    .sidebar-toggler-header,
    .header-icon {
      color: var(--gray-modern-400);

      &.active {
        color: var(--primary-500);
      }
    }

    .nav-icon {
      padding: 5px 8px;
      cursor: pointer;

      &.dropdown-toggle {
        background: transparent;
        box-shadow: none !important;
        width: 36px;
      }
    }

    .header-dropdown-nav {
      margin-left: 12px;
      @extend .dropdown-custom;

      .dropdown-menu {
        border-radius: 8px;
        padding: 0;
        border: 1px solid var(--gray-modern-300);

        .dropdown-header {
          display: flex;
          padding: 12px 16px;
          align-items: center;
          gap: 12px;

          .header-title {
            @extend .text-semibold-sm;
            color: var(--gray-modern-700);
          }

          .header-note {
            @extend .text-sm;
            color: var(--gray-modern-500);
          }
        }

        .dropdown-item {
          @extend .text-medium-sm;
          color: var(--gray-modern-700);
          padding: 10px 16px;
          display: flex;
          align-items: center;
          align-self: stretch;
          gap: 12px;

          &:active {
            color: var(--gray-modern-700);
            background-color: var(--cui-link-hover-color-rgb);
          }
        }

        .dropdown-divider {
          border-top: 1px solid var(--gray-modern-300);
          margin: 0;
          opacity: unset;
        }
      }
    }
  }

  &.header-sticky:not([class*='border']) {
    border-bottom: 1px solid var(--gray-modern-300);
  }

  .dropdown-menu-custom {
    width: 450px;
    max-height: 85vh;
    border-radius: 8px;
    padding: 0;
    border: 1px solid var(--gray-modern-300);
    overflow: auto;
    transform: translate(-207px, 38px) !important;

    .dropdown-menu-header {
      @extend .text-medium-md;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      color: var(--gray-neutral-950);
    }

    .dropdown-menu-action {
      @extend .text-medium-xs;
      color: var(--gray-modern-500);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 24px;
      border-bottom: 1px solid var(--gray-modern-200);

      span {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      svg {
        color: var(--gray-modern-700);
        margin-right: 4px;
      }
    }

    .menu-item {
      display: flex;
      padding: 16px 24px;
      border-bottom: 1px solid var(--gray-modern-100);

      &:hover {
        background-color: var(--primary-50);
      }
    }

    .dropdown-item {
      @extend .text-medium-sm;
      color: var(--gray-modern-700);
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      &:active {
        color: var(--gray-modern-700);
        background-color: var(--cui-link-hover-color-rgb);
      }
    }

    .dropdown-divider {
      border-top: 1px solid var(--gray-modern-200);
      margin: 0;
      opacity: unset;
    }
  }

  @media only screen and (max-width: 576px) {
    .dropdown-menu-custom {
      width: 90vw;
    }
  }

  @media only screen and (max-width: 375px) {
    .dropdown-menu-custom {
      transform: translate(-110px, 38px) !important;
    }
  }
}

.custom-modal {
  .modal-content {
    background-color: var(--base-white);
    border-radius: 8px;
    box-shadow: none;

    .modal-header {
      @extend .text-medium-md;
      border-bottom: none;
      padding: 20px 24px 12px 24px;
      color: var(--gray-modern-900);
    }

    .modal-body {
      @extend .text-semibold-sm;
      padding: 24px;
      color: var(--gray-modern-700);
    }

    .modal-footer {
      background-color: var(--base-white);
      padding: 0 24px 24px 24px;
      border: none;
    }

    .btn-custom {
      &.minw-120 {
        min-width: 120px;
      }

      &.minw-44 {
        min-width: 44px;
      }
    }
  }

  .confirm-delete-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      @extend .text-medium-md;
      color: var(--gray-modern-900);
    }
    .content {
      @extend .text-medium-sm;
      color: var(--gray-modern-500);

      .high-light {
        @extend .text-bold-sm;
        color: var(--gray-modern-900);
      }
    }
  }

  &.confirm-delete-modal {
    .modal-dialog {
      max-width: 412px;
    }
  }
}

.custom-form {
  .invalid-field {
    border-color: var(--error-300) !important;
  }

  .form-height-28 {
    height: 28px !important;
    min-height: 28px;
  }

  .form-height-44 {
    height: 44px;
    min-height: 44px;
  }

  .label-gray-50 {
    color: var(--gray-modern-50);
  }

  .label-gray-400 {
    color: var(--gray-modern-400);
  }

  .label-gray-700 {
    color: var(--gray-modern-700);
  }

  .label-gray-500 {
    color: var(--gray-modern-500);
  }

  .form-label {
    @extend .text-medium-xs;
  }

  .form-check {
    display: flex;
    align-items: center;

    .form-check-label {
      @extend .text-sm;
      color: var(--gray-modern-700);
      margin-left: 8px;
    }

    .form-check-input {
      min-width: 20px;
      min-height: 20px;
      margin-top: 0;
      box-shadow: none !important;
      background-color: var(--base-white);
      border: 1px solid var(--gray-neutral-300);
      border-radius: 6px;

      &:checked {
        background-color: var(--primary-500);
        border: 1px solid var(--primary-500);
      }
    }
  }

  .form-switch {
    display: flex;
    align-items: center;
    .form-check-label {
      @extend .text-sm;
      color: var(--gray-modern-700);
      margin-left: 8px;
    }

    .form-check-input {
      min-width: 20px;
      min-height: 20px;
      margin-top: 0;
      box-shadow: none !important;
      background-color: var(--base-white);
      border: 1px solid var(--gray-neutral-300);
      border-radius: 2em;
      &:checked {
        background-color: var(--primary-500);
        border: 1px solid var(--primary-500);
      }
    }
  }

  .custom-select {
    @extend .text-sm;
    box-shadow: none;

    &.form-height-44 {
      .react-select__control {
        height: 44px;
        min-height: 44px;
      }
    }

    &.form-height-32 {
      .react-select__control {
        height: 32px;
        min-height: 32px;
      }
    }

    &.multi-select {
      &.height-44 {
        .react-select__control {
          // height: ;
          min-height: 44px;
        }
      }
    }

    .react-select__control {
      // height: 44px;
      // min-height: 44px;
      border-radius: 8px;
      border: 1px solid var(--gray-modern-300);
      box-shadow: none;
      .react-select__single-value,
      input,
      .react-select__input-container {
        color: var(--gray-modern-900);
      }
    }

    .react-select__menu {
      .react-select__option {
        color: var(--gray-modern-900);

        &:hover {
          background-color: var(--primary-200);
        }

        &:active {
          background-color: var(--primary-400);
        }

        &.react-select__option--is-focused {
          background-color: var(--primary-200);
        }

        &.react-select__option--is-selected {
          background-color: var(--primary-400);
        }
      }
    }
  }

  .form-control {
    @extend .text-sm;
    border-radius: 8px;
    height: 44px;
    border: 1px solid var(--gray-modern-300);
    padding: 10px 14px;
    color: var(--gray-modern-900);
    background-color: var(--base-white);

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      background-color: var(--gray-modern-50) !important;
      color: var(--gray-modern-500) !important;
    }

    &.is-invalid {
      @extend .invalid-field;
    }

    &::placeholder {
      color: var(--gray-modern-500);
    }
  }

  .date-picker.is-invalid .form-control {
    @extend .invalid-field;
  }

  textarea {
    height: auto !important;
  }

  .form-select {
    @extend .text-sm;
    border-radius: 8px;
    height: 44px;
    border: 1px solid var(--gray-modern-300);
    padding: 10px 14px;
    color: var(--gray-modern-900);
    background-color: var(--base-white);

    &:focus {
      box-shadow: none;
    }

    &.is-invalid {
      @extend .invalid-field;
    }
  }

  .input-end-group {
    align-items: center;

    .input-group-text {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid var(--gray-modern-300);
      border-left: none;
      height: 44px;
      color: var(--gray-neutral-500);
      padding: 10px 14px 10px 0;
      background-color: var(--base-white);
    }

    .form-control {
      @extend .text-sm;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid var(--gray-modern-300);
      border-right: none;
      height: 44px;
      padding: 10px 8px 10px 14px;
      color: var(--gray-modern-900);
      background-color: var(--base-white);

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: var(--gray-modern-500);
      }
    }

    &.is-invalid {
      @extend .invalid-field;
    }
  }

  .input-start-group {
    align-items: center;

    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid var(--gray-modern-300);
      border-right: none;
      height: 44px;
      color: var(--gray-neutral-500);
      padding: 10px 0 10px 14px;
      background-color: var(--base-white);
    }

    .form-control {
      @extend .text-sm;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid var(--gray-modern-300);
      border-left: none;
      height: 44px;
      padding: 10px 14px 10px 8px;
      color: var(--gray-modern-900);
      background-color: var(--base-white);

      &:focus {
        box-shadow: none;
      }

      &::placeholder {
        color: var(--gray-modern-500);
      }
    }

    &.is-invalid {
      @extend .invalid-field;
    }
  }

  .card {
    border: 1px solid var(--gray-modern-200);
    border-radius: 0 0 8px 8px;
    box-shadow: none;
    background-color: white;

    .card-header {
      border-bottom: 1px solid var(--gray-modern-200);
      background-color: white;
      padding: 0;
      border-radius: 0;
    }

    &.card-blue {
      border-top: 2px solid var(--blue-600);
    }

    .btn-custom {
      min-width: 120px;
    }
  }
}

.custom-footer {
  // position: fixed;
  // left: 0;
  // bottom: 0;
  // width: 100%;
  border-top: 1px solid var(--gray-modern-300);
  background-color: var(--base-white);
  border-color: var(--gray-modern-300);
  padding: 16px 24px;

  &.custom-footer-fixed {
    position: fixed;
    bottom: 0;
  }
}

.custom-backdrop {
  position: absolute;
  top: 0;
  z-index: 999;
  background-color: rgb(0, 0, 0, 0.2);

  .text-center {
    width: 100%;
    position: relative;
    height: 100%;
  }

  .spinner-custom {
    position: absolute;
    display: block;
    top: calc(50% - (35px / 2));
    right: calc(50% - (35px / 2));
    color: var(--primary-500);
  }
}


.custom-input-group-action {
  .active {
    background-color: #ff5c00 !important;
    color: #fff;
    border: none;
  }
}
html,
body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Apple Color Emoji',
    'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  color: #fff;
}

.list-chat-fe {
  height: 100dvh !important;
  padding: 8px;

  .active {
    background-color: #3c3c3c !important;

    .message,
    .chat-room-name,
    span {
      color: #fff !important;
    }
  }

  .message {
    color: rgb(112, 117, 121);
    font-size: 14px;
  }

  .chat-room {
    padding: 9px 10px !important;
    border-radius: 12px;
    border: none !important;

    &:hover {
      background-color: #3c3c3c;
    }
  }

  .avatar-54 {
    width: 54px;
    height: 54px;
  }

  .text-truncate-custom {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.background-message {
  // background-image: url('../../img/telegram/background.png');
  background-color: #3c3c3c;
  background-repeat: no-repeat;
  background-size: cover;
}

.chat-info-header-fe {
  height: 56px !important;
}

.chat-info-header-fe {
  svg {
    color: #fff;
  }
}

.chat-box-container-pin-fe {
  height: calc(100dvh - 72px - 80px - 50px) !important;

  @media only screen and (max-width: 767px) {
    height: calc(100dvh - 120px - 50px) !important;
  }

  .chat-box {
    a {
      color: rgb(51, 144, 236);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

}
.chat-box-container-fe {
  height: calc(100dvh - 72px - 80px) !important;

  @media only screen and (max-width: 767px) {
    height: calc(100dvh - 120px) !important;
  }

  .chat-box {
    a {
      color: rgb(51, 144, 236);
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.chat-info-header-fe {
  height: 56px !important;
}

.background-message-list-fe {
  height: calc(100dvh - 56px) !important;
}


.container-custom-fe {
  max-width: 730px;
  margin: auto;
}

.chat-input-container-fe {
  height: 56px !important;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;

  .box-form-chat {
    background-color: #1f2020;
    border-radius: 8px;
    align-items: center;

    textarea {
      background-color: transparent !important;
      color: #fff !important;
      font-size: 14px !important;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }

  .input-group-text {
    border-radius: 50% !important;
    background-color: #1f2020;

    &:hover {
      background-color: rgb(118, 106, 200) !important;

      svg {
        color: #fff !important;
      }
    }

    svg {
      color: rgb(51, 144, 236);
    }
  }
}

.form-add-group {
  .box-avatar {
    // width: 80px;
    // height: 80px;
    .profileImageContainer {
      width: 80px;
      height: 80px;

      .box-select-file {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        background-color: rgb(51, 144, 236);
        border-radius: 50%;

        svg {
          margin: 0 !important;
        }
      }
    }

    .avatar-img {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }

  .p-image {
    align-content: center;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
  }

  .file-upload {
    display: none;
  }
}

.custom-form-register {
  h1 {
    svg {
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }
  }
  .box-custom-form-login {
    position: relative;
    .form-label {
      position: absolute;
      color: #fff;
      background-color: #1f2020;
      top: -7px;
      left: 5px;
      padding: 0 8px;
    }

    .form-control {
      height: 54px;
      background-color: #1f2020;
      color: #fff;
      border: 1px solid #fff;
    }
  }
}

.custom-form-edit-profile {
  h1 {
    svg {
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }
  }
  .box-custom-form-login {
    position: relative;
    .form-label {
      position: absolute;
      color: rgb(112, 117, 121);
      background-color: #fff;
      top: -7px;
      left: 5px;
      padding: 0 8px;
    }

    .form-control {
      height: 54px;
    }
  }
}

.custom-form-login {
  h1 {
    svg {
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }
  }
  .box-custom-form-login {
    position: relative;
    .form-label {
      position: absolute;
      color: #fff;
      background-color: #1f2020;
      top: -7px;
      left: 5px;
      padding: 0 8px;
    }

    .form-control {
      height: 54px;
      background-color: #1f2020;
      color: #fff;
    }
  }
}

.input-message {
  font-size: 16px !important;
  align-content: center;
  color: #fff;
}

.list-item-action {
  .item-list {
    padding: 12px 0;
    &:hover {
      background-color: #3c3c3c;
    }
  }
}

.css-t3ipsp-control:hover {
  border-color: rgb(64, 167, 228) !important;
}

.selecte-language {
  .item-list {
    cursor: pointer;
    padding: 12px 0;
    &:hover {
      background-color: #3c3c3c;
    }
    &.active {
      background-color: #3c3c3c;
    }
  }
}

.list-info {
  .item-list {
    cursor: pointer;
    padding: 12px 0;
    &:hover {
      background-color: #3c3c3c;
    }
  }
}

.list-chat-fe {
  overflow: auto;
  height: calc(100dvh - 108px) !important;

  .chat-room {
    border-bottom: 1px solid var(--gray-modern-100);
    padding: 16px 20px;
    display: flex;
    cursor: pointer;

    &:hover {
      background-color: #3c3c3c;
    }

    &.active {
      background-color: #3c3c3c;
    }

    .chat-room-name {
      @extend .text-medium-sm;
      color: var(--gray-neutral-950);
    }

    .noti-badge {
      @extend .text-medium-xs;
      background-color: var(--gray-modern-200);
      border-radius: 16px;
      color: var(--gray-mordern-500);
      padding: 0 4px;
    }
  }
}

.hiden-mobile {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.avatar-info {
  .avatar {
    width: 70px;
    height: 70px;
  }
}

.modal-add-contact {
  .list-chat-fe {
    max-height: 500px;
    overflow-y: auto;
  }

  .box-search-form {
    border-bottom: 1px solid #e1e1e1;
    .form-control {
      border: none;
      box-shadow: none;
    }
    .input-group-text {
      border: none;
      background-color: transparent;
    }
  }
}

.link-info {
  a {
    color: rgb(51, 144, 236);
    text-decoration: none;
  }
}

.message-info-container-fe {
  max-width: 400px;
  z-index: 9999;
  background-color: #1f2020;
  @media only screen and (max-width: 767px) {
    max-width: 500px;
    border: 0 !important;
  }
}

.action-send-image-and-document {
  box-shadow: 0 0.25rem 0.5rem 0.125rem rgb(114 114 114 / 25%);
  background-color: rgb(255 255 255 / 73%);
  backdrop-filter: blur(10px);
  visibility: hidden;
  transition: 0.3s ease-in-out;
}

.button-action {
  &:hover {
    .action-send-image-and-document {
      visibility: visible;
    }
  }

  &:focus {
    .action-send-image-and-document {
      visibility: visible;
    }
  }

  .item-a {
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: rgb(0 0 0 / 7%) !important;
    }
  }
}

.message-box-fe {
  &.active {
    width: calc(100% - 400px);
    @media only screen and (max-width: 767px) {
      width: calc(100% - 500px);
    }
  }
}

.modal-content {
  background-color: #1f2020;
}

.react-select__menu {
  color: #000;
}

.box-action-message {
  .parent {
    &:hover {
    }
  }
  ul {
    visibility: hidden;
  }
}

.text-truncate-1 {
  -webkit-line-clamp: 1;
}

.text-truncate-2 {
  -webkit-line-clamp: 2;
}

.text-truncate-3 {
  -webkit-line-clamp: 3;
}

.text-truncate-custom {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.highlight {
  border-radius: 8px;
  background-color: #1f2020;
  transition: background-color 0.5s ease-in-out;
}

.list-user-group {
  .item {
    transition: 0.3s ease-in-out;
    &:hover {
      background: rgb(44, 44, 44);
    }
  }
}

.list-chat-folder {
  .item-list {
    transition: 0.3s ease-in-out;
    border-radius: 9px;
    padding: 10px 16px;
    cursor: pointer;

    &:hover {
      background-color: rgb(44, 44, 44);
    }
  }
}

.add-chat-folder {
  transition: 0.3s ease-in-out;
  border-radius: 9px;
  padding: 10px 16px;
  cursor: pointer;

  &:hover {
    background-color: rgb(44, 44, 44);
  }
}

.tabs-header-cotent {
  box-shadow: 0 2px 2px rgb(0 0 0 / 25%);
  .item {
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    opacity: 0.6;
    &.active,
    &:hover {
      border-color: rgb(118, 106, 200);
      color: rgb(118, 106, 200);
      opacity: 1;
    }
  }
}

.action-message-box-fe {
  &:hover, &:focus {
    .action-message-box {
      visibility: visible;
    }
  }
  .action-message-box {
    visibility: hidden;

    div {
      &:hover {
        background-color: #2d2d2d;
      }
    }
  }
}


.modal-fulzise {
  width: 100vw;
  height: 100vh;

  .modal-content, .modal-dialog {
    width: 100vw;
    height: 100vh;
    margin: 0;
    position: relative;

    .modal-header {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      right: 0;
      border: none;
    }
    .modal-body {
      padding: 0;
      margin: 0;

      iframe {
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;

      }
    }
  }

  .box-close {
    position: absolute;
    right: 20px;
    z-index: 999;
    top: 50px;
    cursor: pointer;
  }


}


.content-video-text {
  position: relative;
  cursor: pointer;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}
:root {
  --cui-modal-width: 560px;
}

.light-theme {
  // Base color
  --base-black: #000;
  --base-white: #fff;
  --base-gray: linear-gradient(260deg, #101828 23.64%, #475467 93.17%);

  // Primary colors scheme
  --primary-50: #ffebdf;
  --primary-100: #ffd6be;
  --primary-200: #ffbf9b;
  --primary-300: #ff9f68;
  --primary-400: #ff8038;
  --primary-500: #ff5c00;
  --primary-600: #e45200;
  --primary-700: #d04b00;

  // Error colors scheme
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-200: #fecdca;
  --error-300: #fda29b;
  --error-400: #f97066;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;

  // Warning colors scheme
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;

  // Success colors scheme
  --success-50: #ecfdf3;
  --success-100: #dcfae6;
  --success-200: #a9efc5;
  --success-300: #75e0a7;
  --success-400: #47cd89;
  --success-500: #17b26a;
  --success-600: #079455;
  --success-700: #067647;

  // Gray Mordern colors scheme
  --gray-modern-25: #FCFCFD;
  --gray-modern-50: #f8fafc;
  --gray-modern-100: #eef2f6;
  --gray-modern-200: #e3e8ef;
  --gray-modern-300: #cdd5df;
  --gray-modern-400: #9aa4b2;
  --gray-modern-500: #697586;
  --gray-modern-600: #4b5565;
  --gray-modern-700: #364152;
  --gray-modern-800: #202939;
  --gray-modern-900: #121926;

  // Gray neutral colors scheme
  --gray-neutral-50: #f9fafb;
  --gray-neutral-100: #f3f4f6;
  --gray-neutral-200: #e5e7eb;
  --gray-neutral-300: #d2d6db;
  --gray-neutral-400: #9da4ae;
  --gray-neutral-500: #6c737f;
  --gray-neutral-600: #4d5761;
  --gray-neutral-700: #384250;
  --gray-neutral-800: #1f2a37;
  --gray-neutral-900: #111927;
  --gray-neutral-950: #0D121C;

  // Green colors scheme
  --green-100: #d3f8df;
  --green-200: #aaf0c4;
  --green-300: #73e2a3;
  --green-400: #3ccb7f;
  --green-500: #16b364;
  --green-600: #099250;
  --green-700: #087443;

  // Teal colors scheme
  --teal-100: #ccfbef;
  --teal-200: #99f6e0;
  --teal-300: #5fe9d0;
  --teal-400: #2ed3b7;
  --teal-500: #15b79e;
  --teal-600: #0e9384;
  --teal-700: #107569;

  // Blue colors scheme
  --blue-100: #d1e9ff;
  --blue-200: #b2ddff;
  --blue-300: #84caff;
  --blue-400: #53b1fd;
  --blue-500: #2e90fa;
  --blue-600: #1570ef;
  --blue-700: #175cd3;

  // Blue dark colors scheme
  --blue-dark-100: #d1e0ff;
  --blue-dark-200: #b2ccff;
  --blue-dark-300: #84adff;
  --blue-dark-400: #528bff;
  --blue-dark-500: #2970ff;
  --blue-dark-600: #155eef;
  --blue-dark-700: #004eeb;

  // Purple colors scheme
  --purple-100: #ebe9fe;
  --purple-200: #d9d6fe;
  --purple-300: #bdb4fe;
  --purple-400: #9b8afb;
  --purple-500: #7a5af8;
  --purple-600: #6938ef;
  --purple-700: #5925dc;

  // Pink colors scheme
  --pink-100: #fce7f6;
  --pink-200: #fcceee;
  --pink-300: #faa7e0;
  --pink-400: #f670c7;
  --pink-500: #ee46bc;
  --pink-600: #dd2590;
  --pink-700: #c11574;

  // Rose colors scheme
  --rose-100: #ffe4e8;
  --rose-200: #fecdd6;
  --rose-300: #fea3b4;
  --rose-400: #fd6f8e;
  --rose-500: #f63d68;
  --rose-600: #e31b54;
  --rose-700: #c01048;

  // Orange colors scheme
  --orange-100: #fdead7;
  --orange-200: #f9dbaf;
  --orange-300: #f7b27a;
  --orange-400: #f38744;
  --orange-500: #ef6820;
  --orange-600: #e04f16;
  --orange-700: #b93815;

  // Yellow colors scheme
  --yellow-100: #fef7c3;
  --yellow-200: #feee95;
  --yellow-300: #fde272;
  --yellow-400: #fac515;
  --yellow-500: #eaaa08;
  --yellow-600: #ca8504;
  --yellow-700: #a15c07;

  // background colors scheme
  --bg-primary: #fff;
  --bg-secondary: #f9fafb;
  --bg-tertiary: #f3f4f6;

  // content colors scheme
  --content-primary: #000;
  --content-secondary: #545454;
  --content-tertiary: #757575;

  // border colors scheme
  --border-opaque: #e2e2e2;
  --border-transparent: linear-gradient(0deg, #e2e2e2, #e2e2e2),
    linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08));
  --border-selected: #141414;

  // sidebar theme
  --sidebar-active-bg: linear-gradient(261deg, #101828 -2.74%, #475467 92.31%);
}

.dark-theme {
  // Base color
  --base-black: #000;
  --base-white: #fff;
  --base-gray: linear-gradient(260deg, #101828 23.64%, #475467 93.17%);

  // Primary colors scheme
  --primary-50: #ffebdf;
  --primary-100: #ffd6be;
  --primary-200: #ffbf9b;
  --primary-300: #ff9f68;
  --primary-400: #ff8038;
  --primary-500: #ff5c00;
  --primary-600: #e45200;
  --primary-700: #d04b00;

  // Error colors scheme
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-200: #fecdca;
  --error-300: #fda29b;
  --error-400: #f97066;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;

  // Warning colors scheme
  --warning-50: #fffaeb;
  --warning-100: #fef0c7;
  --warning-200: #fedf89;
  --warning-300: #fec84b;
  --warning-400: #fdb022;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;

  // Success colors scheme
  --success-50: #ecfdf3;
  --success-100: #dcfae6;
  --success-200: #a9efc5;
  --success-300: #75e0a7;
  --success-400: #47cd89;
  --success-500: #17b26a;
  --success-600: #079455;
  --success-700: #067647;

  // Gray Mordern colors scheme
  --gray-modern-50: #f8fafc;
  --gray-modern-100: #eef2f6;
  --gray-modern-200: #e3e8ef;
  --gray-modern-300: #cdd5df;
  --gray-modern-400: #9aa4b2;
  --gray-modern-500: #697586;
  --gray-modern-600: #4b5565;
  --gray-modern-700: #364152;
  --gray-modern-800: #202939;
  --gray-modern-900: #121926;

  // Gray neutral colors scheme
  --gray-neutral-50: #f9fafb;
  --gray-neutral-100: #f3f4f6;
  --gray-neutral-200: #e5e7eb;
  --gray-neutral-300: #d2d6db;
  --gray-neutral-400: #9da4ae;
  --gray-neutral-500: #6c737f;
  --gray-neutral-600: #4d5761;
  --gray-neutral-700: #384250;
  --gray-neutral-800: #1f2a37;
  --gray-neutral-900: #111927;

  // Green colors scheme
  --green-100: #d3f8df;
  --green-200: #aaf0c4;
  --green-300: #73e2a3;
  --green-400: #3ccb7f;
  --green-500: #16b364;
  --green-600: #099250;
  --green-700: #087443;

  // Teal colors scheme
  --teal-100: #ccfbef;
  --teal-200: #99f6e0;
  --teal-300: #5fe9d0;
  --teal-400: #2ed3b7;
  --teal-500: #15b79e;
  --teal-600: #0e9384;
  --teal-700: #107569;

  // Blue colors scheme
  --blue-100: #d1e9ff;
  --blue-200: #b2ddff;
  --blue-300: #84caff;
  --blue-400: #53b1fd;
  --blue-500: #2e90fa;
  --blue-600: #1570ef;
  --blue-700: #175cd3;

  // Blue dark colors scheme
  --blue-dark-100: #d1e0ff;
  --blue-dark-200: #b2ccff;
  --blue-dark-300: #84adff;
  --blue-dark-400: #528bff;
  --blue-dark-500: #2970ff;
  --blue-dark-600: #155eef;
  --blue-dark-700: #004eeb;

  // Purple colors scheme
  --purple-100: #ebe9fe;
  --purple-200: #d9d6fe;
  --purple-300: #bdb4fe;
  --purple-400: #9b8afb;
  --purple-500: #7a5af8;
  --purple-600: #6938ef;
  --purple-700: #5925dc;

  // Pink colors scheme
  --pink-100: #fce7f6;
  --pink-200: #fcceee;
  --pink-300: #faa7e0;
  --pink-400: #f670c7;
  --pink-500: #ee46bc;
  --pink-600: #dd2590;
  --pink-700: #c11574;

  // Rose colors scheme
  --rose-100: #ffe4e8;
  --rose-200: #fecdd6;
  --rose-300: #fea3b4;
  --rose-400: #fd6f8e;
  --rose-500: #f63d68;
  --rose-600: #e31b54;
  --rose-700: #c01048;

  // Orange colors scheme
  --orange-100: #fdead7;
  --orange-200: #f9dbaf;
  --orange-300: #f7b27a;
  --orange-400: #f38744;
  --orange-500: #ef6820;
  --orange-600: #e04f16;
  --orange-700: #b93815;

  // Yellow colors scheme
  --yellow-100: #fef7c3;
  --yellow-200: #feee95;
  --yellow-300: #fde272;
  --yellow-400: #fac515;
  --yellow-500: #eaaa08;
  --yellow-600: #ca8504;
  --yellow-700: #a15c07;

  // background colors scheme
  --bg-primary: #000;
  --bg-secondary: #111927;
  --bg-tertiary: #1f2a37;

  // content colors scheme
  --content-primary: #fff;
  --content-secondary: #afafaf;
  --content-tertiary: #757575;

  // border colors scheme
  --border-opaque: #333333;
  --border-transparent: linear-gradient(0deg, #333333, #333333),
    linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
  --border-selected: #f6f6f6;

  // sidebar theme
  --sidebar-active-bg: linear-gradient(261deg, #101828 -2.74%, #475467 92.31%);
}

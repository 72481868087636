.custom-editor {
  @extend .text-sm;
  .ck-toolbar {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  .ck-content {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
  p {
    margin-bottom: 5px;
  }

  &.size-sm {
    .ck-toolbar {
      zoom: 0.8;
    }
  }

  &.no-border {
    .ck-content {
      border: none !important;
    }
  }
}

.login-container {
    background-image: url('../../img/login-background.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.login-card {
    background-color: white;
    border-radius: 8px;
    // padding: 78px 68px 78px 68px
}
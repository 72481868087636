.project-overview-card {
  margin-bottom: 20px;
  .card-overview-header {
    border: 1px solid var(--gray-modern-200);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px !important;
    background-color: var(--base-white);
    .card-header-title {
      @extend .text-semibold-sm;
      color: var(--gray-modern-900);
    }

    &.card-button {
      padding: 15px 20px !important;
    }
  }

  .card-overview-body {
    border: 1px solid var(--gray-modern-200);
    padding: 0;

    .card-content-section {
      padding: 20px;

      .pie-chart-container {
        width: fit-content;
        max-width: 260px;
        max-height: 260px;
        margin: auto;
      }

      .content-title {
        @extend .text-sm;
        color: var(--gray-modern-400);
        margin-bottom: 7px;
      }

      .content-value-black {
        @extend .text-medium-sm;
        color: var(--gray-modern-900);
      }

      .content-value-black-normal {
        @extend .text-sm;
        color: var(--gray-modern-900);
      }

      .size-point {
        width: 12px;
        height: 12px;

        &.blue-600 {
          background-color: var(--blue-600);
        }

        &.yellow-400 {
          background-color: var(--yellow-400);
        }

        &.green-500 {
          background-color: var(--green-500);
        }

        &.gray-modern-200 {
          background-color: var(--gray-modern-200);
        }
      }

      .content-value-blue {
        @extend .text-medium-sm;
        color: var(--blue-600);
      }

      &.border-top {
        border-top: 1px solid var(--gray-modern-200);
      }

      .logs-container {
        padding-left: 15px;
        padding-bottom: 15px;
        border-left: 1px dashed var(--gray-modern-300);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        .logs-badge {
          transform: translate(-50%, 0%);
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          background-color: var(--gray-modern-200);
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 24px;
          cursor: pointer;

          .logs-icon {
            padding: 0;
            width: 12px;
            height: 12px;
            stroke: var(--gray-modern-500);
          }
        }
        .logs-title {
          @extend .text-sm;
          color: var(--gray-modern-700);
          margin-bottom: 3px;
        }
        .logs-info {
          @extend .text-xs;
          color: var(--gray-modern-400);
          margin-bottom: 3px;
        }
      }
    }
  }
}
